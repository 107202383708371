<template>
	<div class="box">
		<template v-if="ready">
			<div class="row">
				<div class="col-12">
					<label class="col-form-label" for="date_mesure">{{ $t('temperature.date_mesure') }}</label>
					<div class="row">
						<e-datepicker
						id="date_mesure"
						v-model="date_mesure"
						mode="date"
						></e-datepicker>
                        <e-datepicker class="col" v-model="time_mesure" mode="time"></e-datepicker>
                    </div>
				</div>
				<div class="col-12">
					<label class="col-form-label" for="horse_poids">{{ $t('temperature.temperature') }}</label>	
	                <b-form-input
	                    id="horse_temperature"
	                    class="form-control"
	                    type="number"
	                    :placeholder="$t('temperature.temperature')"
	                    v-model="horse_temperature"
	                />
				</div>
				<div class="col-12 mt-3 text-center">
					<b-button v-if="temperature_id" @click.prevent="removeTemperature" class="mr-2"><font-awesome-icon :icon="['fal', 'trash']" /> {{ $t("global.supprimer") }}</b-button>
					<b-button @click.prevent="saveTemperature" variant="primary"><font-awesome-icon v-if="!saving" :icon="['fal', 'save']" /><font-awesome-icon v-if="saving" :icon="['fas', 'spinner']" pulse /> {{ $t("global.enregistrer") }}</b-button>
				</div>
			</div>
		</template>
		<LoadingSpinner v-else />
	</div>
</template>

<script type="text/javascript">
	import Temperature from "@/mixins/Temperature.js"
	import Horse from "@/mixins/Horse.js"

	export default {
		name: 'AddHorseCTemperature',
		mixins: [Temperature, Horse],
		props: ['horse_id'],
		data () {
			return {
				horse: null,
				date_mesure: new Date(),
				time_mesure : new Date(),
				horse_temperature: null,
				temperature_id: null,
				ready: false,
				saving: false
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.reset()
				this.temperature_id = null
				this.horse = await this.getHorseById(this.horse_id)
				if(this.$route.params.temperature_id) {
					this.temperature_id = this.$route.params.temperature_id
					const temperature = await this.getTemperatureById(this.horse_id, this.temperature_id)
					this.date_mesure = new Date(temperature.temperature_date)
                    this.horse_temperature = temperature.temperature_temperature
				}
				this.ready = true
			},

			async saveTemperature() {
				this.saving = true

				let time = this.time_mesure.toString().split(' ')[4]

				const params = {
					time_mesure: time,
					date_mesure: this.date_mesure,
					horse_temperature: this.horse_temperature,
				}

                await this.$sync.force()
                const id = this.$sync.replaceWithReplicated('horse', this.horse_id)

				if(this.temperature_id) {
					if(this.horse_temperature && this.horse_temperature !=0){
						await this.updateTemperature(id, this.temperature_id, params)
						this.reset()
						this.saving = false
						this.$router.push({ name: 'HorseSuiviTemperature', params: { horse_id: this.horse_id }})
					}else{
						this.failureToast(this.getTrad("temperature.egale_zero"), true)
						this.saving = false
					}
				}				
			},

			async removeTemperature() {
				await this.deleteTemperature(this.horse_id, this.temperature_id)
				this.$router.push({ name: 'HorseSuiviTemperature', params: { horse_id: this.horse_id }})
			},

            reset() {
				this.date_mesure = new Date()
				this.horse_temperature = null
            }
		},

		watch: {
			'$route.params.temperature_id'() {
				this.init_component()
			}
		},

		components: {
            LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35') 
		}
	}

</script>